body {
  font: 14px "Noto Sans KR", sans-serif;
  color: #333; }

ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.text-accent {
  color: #2974a4; }

/* convenient utilities */
.inline-block {
  display: inline-block; }

.block {
  display: block; }

.inline {
  display: inline; }

/* margins & paddings */
.mt-50 {
  margin-top: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.pt-50 {
  padding-top: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* hack to smoothe fonts */
.fa {
  transform: rotateZ(0); }

.max-width-100 {
  max-width: 100%; }

.admin-radio {
  white-space: nowrap;
  margin-right: 1.5em;
  line-height: 2em; }

.fm-photo-list li {
  display: block;
  float: left;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin-right: 10px;
  margin-bottom: 10px; }

.fm-photo-list li img {
  height: 120px;
  width: auto;
  border: 2px solid #fff; }

.fm-photo-list li:hover {
  cursor: pointer;
  background-color: #eee;
  border: 1px solid #cc0000; }

.fm-photo-list .caption {
  display: block;
  font-size: 0.6em;
  color: #999;
  word-wrap: break-word; }

/*
 * ckeditor captioned image 
 */
figure.image-captioned {
  padding: 0;
  margin-bottom: 25px; }

figure.image-captioned > img {
  border: 2px solid #ccc; }

figure.image-captioned > figcaption {
  font-size: 0.8em;
  text-align: left;
  color: #999; }

figure.image-captioned figcaption::before {
  content: '<i class="fa fa-chevron-right"></i>'; }

/*# sourceMappingURL=admin.css.map */
